.headerText {
    display: flex;

    h1 {
        color: black;
        font-family: "arial";
        font-size: 4.5rem;
        margin: 10px 0 0 4px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        animation: animtext 4s steps(80, end);
        transition: all cubic-bezier(0.1, 0.7, 1.0, 0.1);
    }

    @keyframes animtext {
        from {
            width: 0;
            transition: all 2s ease-in-out;
        }
    }
}